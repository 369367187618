import React from "react";

export function Dashboard() {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apiadmin.memo.com.az/netdata/dashboard.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="row bg-white rounded d-flex  justify-content-center h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className=" mb-0">Server vəziyyəti</h1>
      </div>
       <div className="d-flex flex-wrap justify-content-center">

      
        <div className="chart_wrapper">
          <h4 className="chart_label">CPU</h4>
          <div
            data-netdata="system.cpu"
            data-chart-library="gauge"
            data-title=""
            data-width="200"
            data-height="200"
            data-after="-300"
            data-points="300"
            data-units="percentage %"
            data-gauge-min-value="0"
            data-before="0"
            data-gauge-max-value="100"
            data-dt-element-name="time703"
            data-decimal-digits="1" 
          ></div>
        </div>

        <div className="chart_wrapper">
          <h4 className="chart_label">RAM</h4>
          <div
            data-netdata="system.ram"
            data-title="RAM istifadəsi"
            data-decimal-digits="4"
            data-chart-library="easypiechart"
            data-colors="#FE3912"
            data-width="200"
            data-height="200"
            data-points="1200"
            data-after="-1200"
            data-dimensions="used"
         
            // data-units="GiB"
            data-easypiechart-max-value="16000"
            //   data-common-max="16000"
            //   data-common-min="0"
          ></div>
        </div>
        
      </div> 


      
    </div>
  );
}
