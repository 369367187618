import React, { useState, useEffect, useRef } from "react";
import ReactSelect from "app/modules/UI/ReactSelect";
import ReactSelectCustom from "../UI/CustomReactSelect";
export function Dashboard() {
  const [Options, setOptions] = useState([]);
  const [selectOption, setSelectOption] = useState({
    value: "netdata.server_cpu",
    label: "netdata.server_cpu",
  });
  const [chartKey, setChartKey] = useState(0);
  const [chartType, setChartType] = useState({
    value: "d3pie",
    label: "d3pie",
  });
  const chartRef = useRef(null);

  useEffect(() => {
    loadNetdataScript();
    return () => {
      removeNetdataScript();
    };
  }, [selectOption, chartType]);

  useEffect(() => {
    fetch("https://apiadmin.memo.com.az/netdata/api/v1/charts")
      .then((response) => response.json())
      .then((data) => {
        if (data.charts) {
          const chartKeys = Object.keys(data.charts).map((key) => ({
            value: key,
            label: key,
          }));
          setOptions(chartKeys);
        }
      })
      .catch((error) => console.error("Error fetching charts:", error));
  }, []);

  const loadNetdataScript = () => {
    removeNetdataScript();
    const script = document.createElement("script");
    script.src = "https://apiadmin.memo.com.az/netdata/dashboard.js";
    script.async = true;
    document.body.appendChild(script);
  };

  const removeNetdataScript = () => {
    const existingScript = document.querySelector(
      'script[src="https://apiadmin.memo.com.az/netdata/dashboard.js"]'
    );
    if (existingScript) {
      document.body.removeChild(existingScript);
    }
  };

  const handleChangeRole = (value) => {
    setSelectOption(value);
    setChartKey((prevKey) => prevKey + 1);
  };

  const handleChartTypeChange = (value) => {
    setChartType(value);
    setChartKey((prevKey) => prevKey + 1);
  };

  const handlePrevChart = () => {
    const currentIndex = Options.findIndex(
      (option) => option.value === selectOption.value
    );
    if (currentIndex > 0) {
      setSelectOption(Options[currentIndex - 1]);
      setChartKey((prevKey) => prevKey + 1);
    }
  };

  const handleNextChart = () => {
    const currentIndex = Options.findIndex(
      (option) => option.value === selectOption.value
    );
    if (currentIndex < Options.length - 1) {
      setSelectOption(Options[currentIndex + 1]);
      setChartKey((prevKey) => prevKey + 1);
    }
  };

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName("MyDropdown__option--is-selected")[0];
      console.log(selectedEl)
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
      }
    }, 15);
  };

  const chartTypeOptions = [
    { value: "d3pie", label: "d3pie" },
    { value: "dygraph", label: "dygraph" },
    { value: "easypiechart", label: "easypiechart" },
    { value: "gauge", label: "gauge" },
    { value: "google", label: "google" },
    { value: "peity", label: "peity" },
    { value: "sparkline", label: "sparkline" },
    { value: "textonly", label: "textonly" },
  ];

  return (
    <div className="row bg-white rounded d-flex justify-content-center h-100">
      <div className="col-12 d-flex align-items-center justify-content-between py-4 height-65">
        <h1 className="mb-0">Server vəziyyəti</h1>
        <div className="Select_chart_type">
          <ReactSelectCustom
           options={Options}
           value={selectOption}
           onChange={handleChangeRole}
           placeholder="Charts"
           isClearable={true}
           />
       
        </div>

        <div className="Chart_type_selector">
        <ReactSelectCustom
           options={chartTypeOptions}
           value={chartType}
           onChange={handleChartTypeChange}
           placeholder="Select Chart Library"
           isClearable={false}
           />
        
        </div>
      </div>
      <div className="row w-100 h-100">
        <div className="col-2 d-flex align-items-center justify-content-center">
          {selectOption && (
            <button
              onClick={handlePrevChart}
              disabled={
                !selectOption ||
                Options.findIndex((option) => option.value === selectOption.value) === 0
              }
            >
              Prev
            </button>
          )}
        </div>
        <div className="col-8 d-flex align-items-center justify-content-center">
          {selectOption && (
            <div
              key={chartKey}
              ref={chartRef}
              data-netdata={selectOption.value}
              data-width={chartType.value === "easypiechart" ? "300" : "700"}
              data-height={chartType.value === "easypiechart" ? "200" : "300"}
              data-after="-300"
              data-points="300"
              data-chart-library={chartType.value}
            ></div>
          )}
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          {selectOption && (
            <button
              onClick={handleNextChart}
              disabled={
                !selectOption ||
                Options.findIndex((option) => option.value === selectOption.value) === Options.length - 1
              }
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
