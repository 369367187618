import React from "react";
import ReactSelect from "react-select";

const ReactSelectCustom = ({
  label,
  placeholder,
  onChange,
  options,
  value,
  required,
  isClearable
}) => {
  return (
    <div className="SelectLocation">
      <label className="mb-2" style={{ color: "var(--dark-text-color)" }}>
        {required ? (
          <>
            {label}{" "}
            <span className="text-danger" style={{ fontWeight: 600 }}>
              {" "}
              *
            </span>
          </>
        ) : (
          <>{label}</>
        )}
      </label>
      <ReactSelect
        // styles={colourStyles}
        options={options}
        value={value}
        placeholder={placeholder}
        className="MyDropdown"
        classNamePrefix="MyDropdown"
        isClearable={isClearable}
        onChange={onChange}
        menuPlacement="auto"
        isSearchable={true}
        id="location-value-select"
        instanceId="location-value-select"
      />
    </div>
  );
};

export default ReactSelectCustom;
